.buscador-container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 52px);
  font-family: "Roboto", Helvetica, sans-serif;
  width: 100%;
  .buscador {
    display: flex;
    flex-direction: column;
    height: 320px;
    justify-content: space-between;
    margin-top: 72px;
    width: 271px;
    &__header {
      align-items: center;
      display: flex;
      flex-direction: column;
      img {
        height: auto;
        width: 100px;
        margin-bottom: 28px;
      }
      h2 {
        font-size: 23px;
        font-weight: 700;
      }
    }

    &__input {
      position: relative;
      input {
        border: 1px solid black;
        border-radius: 15px;
        font-size: 24px;
        font-weight: 400;
        outline: none;
        padding: 5px 16px;
        width: 100%;
        &::placeholder {
          font-weight: 300;
        }
      }
      img {
        right: 9px;
        top: 6px;
        width: 32px;
        position: absolute;
      }
    }
    button {
      align-items: center;
      background: #c41230;
      border: none;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      justify-content: center;
      letter-spacing: 0.3px;
      margin: 0 auto;
      width: 95%;
      &:hover {
        background: darken(#c41230, 10);
      }
      &:disabled {
        background: lighten(#c41230, 45);
      }
    }
  }
}
