.vertical-progress-bar {
    .vpb-bottom, .vpb-top  {
        font-size: 0.6rem;
        text-align: center;
        text-transform: uppercase;
        margin: 10px 0 ;
        @media (min-width: 770px) { 
            font-size: 0.65rem;
        }
        @media (min-width: 990px) { 
            font-size: 0.8rem;
        }
        @media (min-width: 1156px) { 
            font-size: 1rem;
        }
    }
    .vpb-wrapper {
        align-items: flex-end;
        background-color: #d9d9d9;
        border-radius: 12px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        height: 100px;
        overflow: hidden;
        width: 30px;
        font-size: 0.7rem;
        color: #ffffff;
        @media (min-width: 770px) { 
            width: 26px;
            font-size: 0.65rem;
        }
        @media (min-width: 990px) { 
            width: 35px;
            font-size: 0.8rem;
        }
        @media (min-width: 1124px) { 
            width: 40px;
        }
        @media (min-width: 1256px) { 
            width: 50px;
            font-size: 1rem;
        }
        .bar {
            align-content: center;
            align-self: center;
            background-color: #002e7b;
            border-radius: 12px 12px 0 0 ;
            display: flex;
            font-weight: 600;
            justify-content: center;
            width: 100%;
            z-index: 50;
        }

        .percentage {
            position: relative;
            margin-top: auto;
            margin-bottom: auto;
        }

        .shadow {
            background-color: #83aff7;
            border-radius: 12px 12px 0 0 ;
            margin-bottom: -6px;
            width: 100%;
            z-index: 49;
        }
        
    }
}
