.filter-list .float-panel {
    position: absolute;
    z-index: 99;
    max-width: 420px;
    min-width: 280px;
    > div {
        width: 100%;
    }
}

.filter-list .panel-block-bg-white {
    background-color: #ffffff;
}

.filter-list .textarea-filter .tags-filter {
    padding-bottom: 0px;
    max-height: 170px;
}

.filter-list .textarea-filter .content-tags {
    overflow-y: auto;
    margin-top: 5px;
    max-height: 110px;
}

.filter-list .textarea-filter .content-tags .tags.has-addons .tag:first-child{
    margin-right: -1px;
}

.filter-list .textarea-filter button {
    padding-top: 0px;
    margin-right: 3px;
}

.filter-list .field-label {
    min-width: 100px;
    font-size: 0.9em;
}

.filter-list .field-body .select, .filter-list .field-body .select select {
    width: 100%;
}

.filter-list .textarea-filter textarea{
    resize: none;
    overflow: hidden;
}

.filter-list .textarea-filter textarea::placeholder {
    font-size: 11.5px;
}
