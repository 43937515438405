.dropzone {
    margin: auto;
    padding: 30px;
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
}

.dropzone h1.title {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}

.fileInput {
    display: none;
}

.highlight {
    background-color: rgb(188, 185, 236);
}
  
.icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
}

.dropzone-square {
    width: 200px;
    height: 200px;
    border-radius: 0px;
}

.dropzone-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%
}
