.modal-is-xlarge {
    width: 100% !important;
}

.modal-is-large {
    width: 80% !important;
}

.modal-is-medium {
    width: 50% !important;
}

.modal-is-small {
    width: 30% !important;
}

.modal-is-xsmall {
    width: 20% !important;
}

.modal-card {
    overflow: initial !important;
}

.modal-card-foot {
    display: flex !important;
    justify-content: space-evenly !important;
}
