.box.description {
  margin-top: 0;
}

.image-category__wrapper {
  figure {
    margin: 4px 3px 0 auto;
    padding: 2px;
  }
}
