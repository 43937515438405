.multi-progress-bar {
    width: 100%;
    min-height: 80px;
    padding: 10px 0;

    .mpb-title {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        padding: 0 0 6px 0;
    }
    .mpb-wrapper {
        background-color: #d9d9d9;
        color: #ffffff;
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        min-height: 30px;
        overflow: hidden;
        position: relative;
        width: 100%;
        @media (max-width: 990px) { 
            min-height: 20px;
            max-height: 40px;
        }
        > * {
            height: 100%;
            display: inline-block;
            text-align: center;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .mpb-labels {
        display: flex;
        font-weight: 400;
        flex-wrap: wrap;
        > div {
            align-content: center;
            display: flex;
            font-size: 0.8rem;
            padding: 0 15px;
            @media (min-width: 990px) { 
                font-size: .9rem;
            }
            @media (min-width: 1156px) { 
                font-size: 1rem;
            }
            span {
                font-size: 22px;
                &::after {
                    content: " ";
                    padding: 3px;
                }
            }
        };
    }
}
