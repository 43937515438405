@keyframes fadeinout {
    0% {
      opacity: 1;
      position: relative;
    }
    100% {
      opacity: 0;
      display: none;
      position: absolute;
      top: 0;
    //   z-index: 1;
    }
}

@keyframes fadeoutin {
    0% {
      opacity: 0;
      display: none !important;
    }
    100% {
      opacity: 1;
    }
}

.v-dashboard {
    height: 100%;
    max-height: 100%;
    margin: 0px;
    > div {
        height: 100%;
        max-height: 100%;
        overflow-y: hidden;
    }
    &__data {
        padding: 45px;
        padding-top: 25px !important;
        padding-bottom: 25px !important;
        height: 95%;
        margin-left: auto;
        margin-right: auto;
        max-width: 1250px;
        overflow-y: hidden;
        overflow-x: hidden;
        .d-header > div, .d-pdp-general > div:first-child {
            display: flex;
            align-items: flex-end;

            &:nth-child(2) {
                justify-content: flex-end;
            }
        }
        > div {
            .header {
                border-bottom: #000 1px solid;
                padding-bottom: 0px !important;
            }
        }
        .d-empty-filters {
            align-items: center;
            display: flex;
            height: 100%;
            font-size: 2rem;
            justify-content: center;
            opacity: 1;
            width: 100%;
            flex-direction: column;
            text-align: center;
            text-transform: uppercase;
            animation: fadeoutin 1s linear forwards;
            > div {
                align-content: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:first-child { height: 90%; }
                &:last-child { 
                    height: 10%;
                    font-size: 1.4rem;
                }
            }
            svg {
                width: 10rem;
                margin: 0px auto;
            }
        }

        .d-loading-request {
            opacity: 0;
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            font-size: 2rem;
            width: 100%;
            flex-direction: column;
            text-align: center;
            text-transform: uppercase;

            &.notVisible {
                animation: fadeinout 1.5s linear forwards;
            }
            &.isVisible {
                animation: fadeoutin 1s linear forwards;
            }
            > div {
                align-content: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            svg {
                &:first-child {
                    height: auto;
                    width: 6rem;
                    margin-bottom: 3rem;
                }
                width: 10rem;
                margin: 0px auto;
            }
        }

        .d-pdp-general {
            .d-pdp-charts > div {
                padding-right: 20px;
                &:last-child {
                    padding-left: 20px;
                    border-left: 1px solid transparent;
                    border-image: linear-gradient(to bottom, white, white, black, black, white, white) 1;
                }
            }
            .d-tags {
                display: flex;
                justify-content: flex-end;
                max-width: 60%;
                div { padding: 0px; }
                div:not(:last-child)::after {
                    content: "|";
                    padding: 15px;
                }
            }
        }
        .d-pdp-month {
            .d-charts {
                display: flex;
                gap: 5px;
                flex-wrap: wrap;
                justify-content: space-around;

                &:last-child {
                    border-left: 1px solid transparent;
                    border-image: linear-gradient(to bottom, white, white, black, black, white, white) 1;
                    justify-content: space-evenly;
                }
            }
        }
        .d-photo-schedule {
            table {
                th, td {
                    border: none;
                }
            }
        }
    }

    &__filters {
        height: 100%;
        background-color: #efefef;
        
        .title {
            text-align: center;
            font-size: 22px;
            margin-bottom: 10px;
            margin-top: 15px;
        }
        div.control, title {
            margin-top: 30px;
        }
        div.select, select {
            width: 100%;
        }

        .action-btn {
            button {
                font-size: inherit;
                &.underline {
                    background-color: transparent;
                    border: none;
                    text-decoration: underline;
                    svg { margin-right: 5px;}
                    
                }
            }
            @media (max-width: 770px) { 
                font-size: 1rem;
            }
            @media (min-width: 771px) { 
                font-size: 1.2rem;
            }
        }
    }

    
}
