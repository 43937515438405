.compra-asistida {
  background: #3cbea5;
  border-radius: 1px;
  color: white;
  padding: 12px;
  position: relative;
  /*  @media (min-width: 768px) {
    padding: 18px 30px;
    margin-right: 9px;
  } */
  a {
    color: white;
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
    &:visited {
      color: white;
    }
  }
  h3 {
    font-size: 32px;
    font-weight: bolder;
    letter-spacing: 0.3px;
  }
  span {
    font-size: 11px;
    font-weight: 400;
  }
  &__icon {
    top: -30%;
    height: auto;
    position: absolute;
    right: -6px;
    width: 90px;
  }
}
