html {
  overflow-y: auto;
}

.content-app {
 margin-bottom: 0px !important;
}

.is-borderless {
  border: 0 !important;
}

.is-bg-transparent {
  background: transparent !important;
}

.react-contextmenu-wrapper {
  .muestra-context {
    align-items: center;
    display: flex;
    flex-direction: column;
    .image {
      margin: 4px 0 0;
      left: 2px;
      position: relative;
      .is-rounded {
        border: 1px solid #d2d8d8;
        height: 50px;
        width: 50px;
      }
    }
  }
}
.react-contextmenu {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 1px 1px 8px #cfcfcf;
  color: black;
  cursor: pointer;
  display: none;
  margin-left: -75px;
  margin-top: -75px;
  position: relative;
  z-index: 999;
  &--visible {
    display: block;
  }
  &-item {
    align-items: center;
    display: flex;
    font-size: 17px;
    min-height: 40px;
    outline: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    button {
      align-items: center;
      background: none;
      border: none;
      display: flex;
      font-size: 16px;
      height: 40px;
      overflow: hidden;
      outline: none;
      padding: 2px 10px;
      position: relative;
      width: 100%;
      &:after {
        background: rgba(black, 0.3);
        border-radius: 100%;
        content: "";
        height: 0;
        left: 50%;
        position: absolute;
        top: 50%;
        transform-style: flat;
        transform: translate3d(-50%, -50%, 0);
        transition: width 0.6s ease, height 0.6s ease;
        width: 0;
      }
      &:active {
        &:after {
          height: 500px;
          width: 500px;
        }
      }
      .zoom-in,
      .zoom-out {
        background: url(./components/Items/components/Details/components/assets/zoom-in.svg)
          no-repeat center;
        background-size: 18px;
        display: block;
        height: 40px;
        margin: 0;
        width: 40px;
      }
      .zoom-out {
        background-image: url(./components/Items/components/Details/components/assets/zoom-out.svg);
      }
      .zoom-in,
      .zoom-out {
        display: block;
        background: url(./components/Items/components/Details/components/assets/zoom-in.svg)
          no-repeat center;
        background-size: 18px;
        height: 40px;
        margin: 0;
        width: 40px;
      }
      .zoom-out {
        background-image: url(./components/Items/components/Details/components/assets/zoom-out.svg);
      }
    }

    &:hover {
      background: #2f6ac9;
      color: white;
      button {
        color: white;
      }
      .zoom-in {
        background-image: url(./components/Items/components/Details/components/assets/zoom-in-fill.svg);
      }
      .zoom-out {
        background-image: url(./components/Items/components/Details/components/assets/zoom-out-fill.svg);
      }
    }
    .muestra-context {
      padding: 0 10px;
    }
    &--disabled {
      background: #e24a4a;
      padding-left: 40px;
      text-decoration: line-through;
      text-decoration-color: white;
      button {
        color: white;
        .zoom-in {
          display: none;
        }
        .zoom-out {
          display: none;
        }
      }

      &:hover {
        background: #e24a4a;
        color: white;
      }
    }
  }
}
.Eliminar_muestra {
  margin: 0 !important;
  padding: 0 10px;
  &:hover {
    background: #e24a4a !important;
    color: white;
  }
  .react-contextmenu-item {
    &:hover {
      background: inherit;
    }
  }
}
.total-zoom {
  align-items: center;
  border-bottom: 1px solid black;
  display: flex;
  font-weight: 600;
  height: 35px;
  justify-content: center;
  padding-left: 5px;
  p {
    width: 73%;
  }
  span {
    align-items: center;
    border-left: 1px solid black;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    width: 27%;
  }
}

.react-confirm-alert-overlay {
  z-index: 100;
}

.bg-png {
  background-image:url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gKwSUNDX1BST0ZJTEUAAQEAAAKgbGNtcwQwAABtbnRyUkdCIFhZWiAH5QAEAA8AEQAHAAphY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA1kZXNjAAABIAAAAEBjcHJ0AAABYAAAADZ3dHB0AAABmAAAABRjaGFkAAABrAAAACxyWFlaAAAB2AAAABRiWFlaAAAB7AAAABRnWFlaAAACAAAAABRyVFJDAAACFAAAACBnVFJDAAACFAAAACBiVFJDAAACFAAAACBjaHJtAAACNAAAACRkbW5kAAACWAAAACRkbWRkAAACfAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACQAAAAcAEcASQBNAFAAIABiAHUAaQBsAHQALQBpAG4AIABzAFIARwBCbWx1YwAAAAAAAAABAAAADGVuVVMAAAAaAAAAHABQAHUAYgBsAGkAYwAgAEQAbwBtAGEAaQBuAABYWVogAAAAAAAA9tYAAQAAAADTLXNmMzIAAAAAAAEMQgAABd7///MlAAAHkwAA/ZD///uh///9ogAAA9wAAMBuWFlaIAAAAAAAAG+gAAA49QAAA5BYWVogAAAAAAAAJJ8AAA+EAAC2xFhZWiAAAAAAAABilwAAt4cAABjZcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltjaHJtAAAAAAADAAAAAKPXAABUfAAATM0AAJmaAAAmZwAAD1xtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAEcASQBNAFBtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEL/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAUABQDAREAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAUDCP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeqSMHEDTQjFkQf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAgMEFDMQ/9oACAEBAAEFAix1NDkcaypnTyvyP//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAECABAyEf/aAAgBAQAGPwKNWoGYdJmaWv/EABoQAAIDAQEAAAAAAAAAAAAAAAGxABDwEUH/2gAIAQEAAT8hiSrICegZHsyJpp1//9oADAMBAAIAAwAAABCQQACf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAgEBPxAf/8QAHBAAAQQDAQAAAAAAAAAAAAAAAQAQIfARYdEx/9oACAEBAAE/EFU0arkpEGlDOCQIEeAKrs1TZv/Z');
  background-size: 20px 20px;
  background-position: 0 0;
  background-repeat: repeat;

  .magnifying-glass {
    background-color: #ff0000 !important;
  }
}
