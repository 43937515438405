.is-responsive {
    overflow-x: auto;
}

.table th.iconType, .table td.iconType {
    width: 100px !important;
}

.table th.checkType, .table td.checkType {
    width: 40px !important;
}

.datatable {
    .table-container {
        max-height: calc(100vh - 320px);
        height: calc(100vh - 320px);
        border: 1px solid #ddd;
        width: 100%;
        overflow-y: auto;

        .table {
           
            table {
                width: 100%;
                border-collapse: separate;
                border: none;
            }

            thead {
                tr {
                    box-shadow:  0 -1px 2px gray;
                    th {
                        text-align: left;
                        background: #ffffff;
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0px;
                        z-index: 10;
                    }
                }
            }
        }
    }

}


