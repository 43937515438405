.info-card {
  background: white;
  border-radius: 1px;
  padding: 14px 14px 2px 20px;
  /*  @media (min-width: 768px) {
    padding: 28px 28px 28px 34px;
  }
  @media (min-width: 1440px) {
    padding: 28px 28px 28px 40px;
  } */
  &__title {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 11px;
  }
  &__shortDescription {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &__ts,
  &__c {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &__ts {
    font-size: 20px;
    span {
      margin-right: auto;
    }
  }
  &__c {
    margin: 23px 0 27px;
    padding-right: 40px;
    &-item {
      display: flex;
      flex-direction: column;
      span {
        font-size: 20px;
        font-weight: 300;
        &:first-child {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
  &__description {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 23px;
  }
  &__destacado {
    h4 {
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 27px;
    }
    ul {
      li {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: 400;
        &:last-child {
          margin-bottom: 10px;
        }
        &::before {
          content: "-";
          margin-right: 5px;
        }
      }
    }
  }
}
