body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-is-xlarge {
  width: 100% !important; }

.modal-is-large {
  width: 80% !important; }

.modal-is-medium {
  width: 50% !important; }

.modal-is-small {
  width: 30% !important; }

.modal-is-xsmall {
  width: 20% !important; }

.modal-card {
  overflow: initial !important; }

.modal-card-foot {
  display: flex !important;
  justify-content: space-evenly !important; }

.navbar-burger {
  margin-left: 0px !important;
  display: block !important; }

.dt-actions div.dropdown :not(:last-child) {
  margin-right: .5rem; }

.dt-actions div.dropdown .dropdown-menu {
  padding-top: unset; }

.filter-list .float-panel {
  position: absolute;
  z-index: 99;
  max-width: 420px;
  min-width: 280px; }
  .filter-list .float-panel > div {
    width: 100%; }

.filter-list .panel-block-bg-white {
  background-color: #ffffff; }

.filter-list .textarea-filter .tags-filter {
  padding-bottom: 0px;
  max-height: 170px; }

.filter-list .textarea-filter .content-tags {
  overflow-y: auto;
  margin-top: 5px;
  max-height: 110px; }

.filter-list .textarea-filter .content-tags .tags.has-addons .tag:first-child {
  margin-right: -1px; }

.filter-list .textarea-filter button {
  padding-top: 0px;
  margin-right: 3px; }

.filter-list .field-label {
  min-width: 100px;
  font-size: 0.9em; }

.filter-list .field-body .select, .filter-list .field-body .select select {
  width: 100%; }

.filter-list .textarea-filter textarea {
  resize: none;
  overflow: hidden; }

.filter-list .textarea-filter textarea::-webkit-input-placeholder {
  font-size: 11.5px; }

.filter-list .textarea-filter textarea::placeholder {
  font-size: 11.5px; }

.is-responsive {
  overflow-x: auto; }

.table th.iconType, .table td.iconType {
  width: 100px !important; }

.table th.checkType, .table td.checkType {
  width: 40px !important; }

.datatable .table-container {
  max-height: calc(100vh - 320px);
  height: calc(100vh - 320px);
  border: 1px solid #ddd;
  width: 100%;
  overflow-y: auto; }
  .datatable .table-container .table table {
    width: 100%;
    border-collapse: separate;
    border: none; }
  .datatable .table-container .table thead tr {
    box-shadow: 0 -1px 2px gray; }
    .datatable .table-container .table thead tr th {
      text-align: left;
      background: #ffffff;
      position: -webkit-sticky;
      position: sticky;
      top: 0px;
      z-index: 10; }

.modal-is-large {
  width: 80% !important; }

.modal-is-medium {
  width: 50% !important; }

.modal-is-small {
  width: 30% !important; }

.modal-is-xsmall {
  width: 20% !important; }

.modal-card {
  overflow: initial !important; }

.modal-card-foot {
  display: flex !important;
  justify-content: space-evenly !important; }

.details-images .header-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  .details-images .header-images button {
    margin-left: 3px;
    margin-right: 3px; }

.box.description {
  margin-top: 0; }

.image-category__wrapper figure {
  margin: 4px 3px 0 auto;
  padding: 2px; }

.dropzone {
  margin: auto;
  padding: 30px;
  background-color: #fff;
  border: 2px dashed #bbbaba;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px; }

.dropzone h1.title {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */ }

.fileInput {
  display: none; }

.highlight {
  background-color: #bcb9ec; }

.icon {
  opacity: 0.3;
  height: 64px;
  width: 64px; }

.dropzone-square {
  width: 200px;
  height: 200px;
  border-radius: 0px; }

.dropzone-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%; }

.support .btn-support {
  position: fixed;
  z-index: 100;
  top: calc(100vh - 120px);
  right: 0; }

.commentFullWidth {
    width: 100% !important;
}
.generate-channel-link {
  min-height: 250px;
  max-height: 60vh; }

.generate-channel-link > div.box {
  display: flex; }

.generate-channel-link > div.box:last-child {
  margin-top: 0.5rem;
  justify-content: space-between;
  flex-direction: column; }

.generate-channel-link > div.box:last-child > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; }

.generate-channel-link > div.box:first-child {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.5rem; }

.generate-channel-link > div.box:first-child > div {
  display: flex;
  align-items: center;
  justify-content: center; }

.modal-package .header {
  margin-top: 0px; }

.package-share-width .shared, .package-share-width .filtered {
  min-height: 150px;
  max-height: 200px;
  overflow-y: auto; }

.package-share-width .shared .tags {
  margin-bottom: 0px; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif !important; }

.rbc-event {
  background-color: #359CEE; }

.rbc-calendar .rbc-event-content,
.rbc-calendar .rbc-toolbar-label,
.rbc-calendar .rbc-header,
.rbc-calendar .rbc-date-cell,
.rbc-calendar .rbc-btn-group,
.rbc-calendar .rbc-time-slot,
.rbc-calendar .rbc-label {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif !important; }

.sidebar-menu {
  max-width: 200px; }

.compra-asistida {
  background: #3cbea5;
  border-radius: 1px;
  color: white;
  padding: 12px;
  position: relative;
  /*  @media (min-width: 768px) {
    padding: 18px 30px;
    margin-right: 9px;
  } */ }
  .compra-asistida a {
    color: white; }
    .compra-asistida a:hover {
      color: white; }
    .compra-asistida a:active {
      color: white; }
    .compra-asistida a:visited {
      color: white; }
  .compra-asistida h3 {
    font-size: 32px;
    font-weight: bolder;
    letter-spacing: 0.3px; }
  .compra-asistida span {
    font-size: 11px;
    font-weight: 400; }
  .compra-asistida__icon {
    top: -30%;
    height: auto;
    position: absolute;
    right: -6px;
    width: 90px; }

.info-card {
  background: white;
  border-radius: 1px;
  padding: 14px 14px 2px 20px;
  /*  @media (min-width: 768px) {
    padding: 28px 28px 28px 34px;
  }
  @media (min-width: 1440px) {
    padding: 28px 28px 28px 40px;
  } */ }
  .info-card__title {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 11px; }
  .info-card__shortDescription {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px; }
  .info-card__ts, .info-card__c {
    align-items: center;
    display: flex;
    justify-content: space-between; }
  .info-card__ts {
    font-size: 20px; }
    .info-card__ts span {
      margin-right: auto; }
  .info-card__c {
    margin: 23px 0 27px;
    padding-right: 40px; }
    .info-card__c-item {
      display: flex;
      flex-direction: column; }
      .info-card__c-item span {
        font-size: 20px;
        font-weight: 300; }
        .info-card__c-item span:first-child {
          font-size: 12px;
          font-weight: 600; }
  .info-card__description {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 23px; }
  .info-card__destacado h4 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 27px; }
  .info-card__destacado ul li {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400; }
    .info-card__destacado ul li:last-child {
      margin-bottom: 10px; }
    .info-card__destacado ul li::before {
      content: "-";
      margin-right: 5px; }

.carrusel {
  position: relative;
  height: 100%;
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
  display: flex; }
  .carrusel__right, .carrusel__left {
    background: white;
    box-shadow: 0px 0px 2px #2222229f;
    color: #929292;
    border-radius: 100%;
    font-weight: lighter;
    height: 45px;
    padding: 13px 16px 13px 13px;
    position: absolute;
    z-index: 99;
    top: 40%;
    width: 45px !important; }
  .carrusel__right {
    right: 10px; }
    @media (min-width: 768px) {
      .carrusel__right {
        right: 9%; } }
    @media (min-width: 1200px) {
      .carrusel__right {
        right: 15%; } }
  .carrusel__left {
    left: 10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    @media (min-width: 768px) {
      .carrusel__left {
        left: 9%; } }
    @media (min-width: 1200px) {
      .carrusel__left {
        left: 15%; } }
  .carrusel img {
    height: auto;
    width: 100%;
    pointer-events: none;
    object-fit: contain; }
  .carrusel__dots {
    align-items: center;
    bottom: 0px;
    display: flex;
    justify-content: center;
    margin-bottom: -15px;
    position: absolute;
    width: 100%; }
    @media (min-width: 1200px) {
      .carrusel__dots {
        display: none; } }
    .carrusel__dots-circle {
      border-radius: 100%;
      background: rgba(182, 182, 182, 0.74);
      display: block;
      height: 9px;
      margin-left: 5px;
      width: 9px; }
      .carrusel__dots-circle:first-child {
        margin-left: 0; }
      .carrusel__dots-circle-active {
        background: black; }

.carrusel-mini {
  display: none; }
  @media (min-width: 1200px) {
    .carrusel-mini {
      height: 100%;
      left: -13%;
      position: absolute;
      overflow: hidden;
      scroll-behavior: smooth;
      top: 0;
      width: 90px;
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      gap: 10px; }
      .carrusel-mini__img {
        align-items: center;
        border: 1px solid #eee;
        cursor: pointer;
        display: flex;
        height: 90px;
        justify-content: center;
        width: 90px; }
        .carrusel-mini__img-active {
          border-color: #222; }
        .carrusel-mini__img img {
          max-height: 80px;
          object-fit: contain; } }

.notFound {
  align-items: center;
  color: #222222;
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto", Helvetica, sans-serif;
  justify-content: center; }
  @media (min-width: 768px) {
    .notFound {
      flex-wrap: nowrap; } }
  .notFound__error {
    align-items: center;
    flex-direction: column;
    display: flex; }
    @media (min-width: 768px) {
      .notFound__error {
        order: 2; } }
  .notFound__ops {
    font-size: 23px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center; }
    .notFound__ops span {
      font-size: 30px;
      font-weight: bolder; }
    .notFound__ops.mobile {
      display: block; }
      @media (min-width: 768px) {
        .notFound__ops.mobile {
          display: none; } }
    .notFound__ops.tablet {
      display: none; }
      @media (min-width: 768px) {
        .notFound__ops.tablet {
          margin-bottom: 0;
          text-align: left;
          line-height: 40px;
          display: block; } }
  @media (min-width: 768px) {
    .notFound__info {
      max-width: 370px; } }
  .notFound__buscar {
    font-size: 22px;
    margin: 20px 0; }
    @media (min-width: 768px) {
      .notFound__buscar {
        font-size: 18px; } }
  .notFound h1 {
    -webkit-text-stroke: 1px #354a70;
    color: #69829c;
    font-size: 170px;
    font-weight: bolder;
    line-height: 100px;
    text-align: center;
    text-shadow: 1px 1px 1px #354a70, 2px 2px 1px #354a70;
    width: 100%; }
    @media (min-width: 768px) {
      .notFound h1 {
        font-size: 200px;
        line-height: 150px; } }
  .notFound__svg {
    position: relative;
    left: 17px;
    max-height: 400px; }
    @media (min-width: 768px) {
      .notFound__svg {
        font-size: 25px;
        max-height: 600px; } }
  .notFound__Ir {
    align-items: center;
    background: #c41230;
    border: 1px solid #c41230;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    letter-spacing: 0.3px;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 100%; }
    .notFound__Ir:hover {
      background: #950e25;
      color: #ffffff; }

.spiner {
  align-items: center;
  display: flex;
  flex-direction: column; }
  .spiner span {
    color: #222;
    font-family: "Roboto", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 20px;
    text-align: center;
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1); }
  .spiner .lds-roller {
    margin-top: 50px;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px; }
    .spiner .lds-roller div {
      -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
              animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      -webkit-transform-origin: 40px 40px;
              transform-origin: 40px 40px; }
      .spiner .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #222;
        margin: -4px 0 0 -4px; }
      .spiner .lds-roller div:nth-child(1) {
        -webkit-animation-delay: -0.036s;
                animation-delay: -0.036s; }
        .spiner .lds-roller div:nth-child(1):after {
          top: 63px;
          left: 63px; }
      .spiner .lds-roller div:nth-child(2) {
        -webkit-animation-delay: -0.072s;
                animation-delay: -0.072s; }
        .spiner .lds-roller div:nth-child(2):after {
          top: 68px;
          left: 56px; }
      .spiner .lds-roller div:nth-child(3) {
        -webkit-animation-delay: -0.108s;
                animation-delay: -0.108s; }
        .spiner .lds-roller div:nth-child(3):after {
          top: 71px;
          left: 48px; }
      .spiner .lds-roller div:nth-child(4) {
        -webkit-animation-delay: -0.144s;
                animation-delay: -0.144s; }
        .spiner .lds-roller div:nth-child(4):after {
          top: 72px;
          left: 40px; }
      .spiner .lds-roller div:nth-child(5) {
        -webkit-animation-delay: -0.18s;
                animation-delay: -0.18s; }
        .spiner .lds-roller div:nth-child(5):after {
          top: 71px;
          left: 32px; }
      .spiner .lds-roller div:nth-child(6) {
        -webkit-animation-delay: -0.216s;
                animation-delay: -0.216s; }
        .spiner .lds-roller div:nth-child(6):after {
          top: 68px;
          left: 24px; }
      .spiner .lds-roller div:nth-child(7) {
        -webkit-animation-delay: -0.252s;
                animation-delay: -0.252s; }
        .spiner .lds-roller div:nth-child(7):after {
          top: 63px;
          left: 17px; }
      .spiner .lds-roller div:nth-child(8) {
        -webkit-animation-delay: -0.288s;
                animation-delay: -0.288s; }
        .spiner .lds-roller div:nth-child(8):after {
          top: 56px;
          left: 12px; }

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@page {
  size: letter portrait;
  margin: 0mm; }

@media print {
  .modal-container,
  .Toastify,
  .Toastify__toast-body,
  nav,
  .support,
  svg,
  .curbside button,
  .carrusel svg,
  .carrusel-mini {
    display: none; }
  .curbside__header-logo-levis,
  .curbside__header-logo-dockers {
    display: block; } }

.imprimir {
  align-items: center;
  background: none;
  border: none;
  color: #c41230;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.3px;
  margin: 20px auto 0px;
  outline: none;
  padding-bottom: 2px;
  transition: 0.3s; }
  .imprimir:hover {
    border-bottom: 1px solid #c41230; }
  .imprimir svg {
    font-size: 20px;
    margin-right: 9px;
    position: relative; }
  @media (min-width: 1200px) {
    .imprimir {
      margin-right: 22%;
      max-width: 35%; } }

.curbside {
  background: #f7f7f7;
  color: #222222;
  font-family: "Roboto", Helvetica, sans-serif;
  min-height: 100vh;
  margin-top: -12px;
  margin-bottom: -20px;
  padding-bottom: 16px; }
  @media (min-width: 768px) {
    .curbside {
      background: #ffffff; } }
  .curbside.info {
    left: 0;
    position: absolute;
    top: 0;
    margin: 0;
    width: 100vw; }
  .curbside.error {
    padding: 0 9%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .curbside__link-copy {
    height: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
    z-index: -1; }
  .curbside__header {
    align-items: center;
    background: #ffffff;
    border-bottom: solid 1px #eee;
    display: flex;
    height: 60px;
    justify-content: space-between;
    margin-bottom: 9px;
    padding: 0 18px;
    position: relative; }
    @media (min-width: 768px) {
      .curbside__header {
        padding: 0 9%; } }
    .curbside__header-logo-dockers {
      font-size: 7vw; }
      @media (min-width: 768px) {
        .curbside__header-logo-dockers {
          font-size: 38px; } }
    .curbside__header-logo-levis {
      max-width: 65px; }
      @media (min-width: 768px) {
        .curbside__header-logo-levis {
          max-width: 85px; } }
    .curbside__header-pc9 {
      cursor: default;
      font-size: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      position: absolute;
      pointer-events: none;
      width: 100%; }
    .curbside__header .curbside__share {
      box-sizing: border-box;
      color: black;
      cursor: pointer;
      height: 45px;
      padding: 10px;
      width: 45px; }
      @media (min-width: 768px) {
        .curbside__header .curbside__share {
          height: 48px;
          width: 48px; } }
      .curbside__header .curbside__share:hover {
        background: #f8f8f8; }
  .curbside__flexContainer {
    padding: 0 9px; }
    @media (min-width: 768px) {
      .curbside__flexContainer {
        padding: 0 9%; } }
    @media (min-width: 1200px) {
      .curbside__flexContainer {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 60px; } }
  .curbside__imagenes {
    background: #ffffff;
    height: 359px;
    margin-bottom: 23px;
    position: relative;
    width: 100%; }
    @media (min-width: 768px) {
      .curbside__imagenes {
        height: 550px; } }
    @media (min-width: 1200px) {
      .curbside__imagenes {
        width: 55%; } }
  .curbside__body, .curbside__compra {
    margin-bottom: 9px; }
  @media (min-width: 1200px) {
    .curbside__body {
      width: 45%; } }
  .curbside__compra {
    margin-top: 16px;
    box-sizing: border-box; }
    @media (min-width: 1200px) {
      .curbside__compra {
        width: 45%;
        order: 4; } }
  .curbside__faqs {
    background: #ffffff;
    border-radius: 1px;
    box-sizing: border-box;
    padding: 1px 12px 2px;
    margin-bottom: 50px;
    margin-top: 16px; }
    @media (min-width: 1200px) {
      .curbside__faqs {
        box-sizing: border-box;
        margin: -30px 0 0;
        width: 55%; } }
    .curbside__faqs-item {
      align-items: center;
      display: flex;
      margin: 12px 0; }
      .curbside__faqs-item a,
      .curbside__faqs-item span {
        color: #222;
        font-size: 15px;
        font-weight: 400;
        margin-left: 8px; }
      .curbside__faqs-item svg {
        color: black; }
      @media (min-width: 1200px) {
        .curbside__faqs-item:first-child {
          margin-right: 40px;
          padding-top: 30px;
          border-top: solid 1px #eee; } }
  .curbside__footer {
    align-items: center;
    bottom: 0;
    background: #ffffff;
    border-top: solid 1px #eee;
    display: flex;
    height: 49px;
    justify-content: space-between;
    left: 0;
    margin-top: 9px;
    padding: 0 18px;
    position: absolute;
    right: 0; }
    .curbside__footer a,
    .curbside__footer span {
      color: #222222;
      font-size: 15px;
      font-weight: 400; }
    .curbside__footer a {
      height: 20px;
      box-sizing: border-box; }
      .curbside__footer a:hover {
        border-bottom: 1px solid #222; }
    @media (min-width: 768px) {
      .curbside__footer {
        padding: 0 9%; } }
  .curbside__compartelo {
    align-items: center;
    background: #c41230;
    border: 1px solid #c41230;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    letter-spacing: 0.3px;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 20px;
    width: 95%; }
    .curbside__compartelo:hover {
      background: #950e25; }
    .curbside__compartelo svg {
      font-size: 10px;
      margin-right: 9px;
      position: relative; }
    @media (min-width: 768px) {
      .curbside__compartelo {
        margin: 0 auto;
        max-width: 80%; } }
    @media (min-width: 1200px) {
      .curbside__compartelo {
        margin: 0;
        margin-left: auto;
        margin-right: 9%;
        max-width: 35%; } }

.buscador-container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 52px);
  font-family: "Roboto", Helvetica, sans-serif;
  width: 100%; }
  .buscador-container .buscador {
    display: flex;
    flex-direction: column;
    height: 320px;
    justify-content: space-between;
    margin-top: 72px;
    width: 271px; }
    .buscador-container .buscador__header {
      align-items: center;
      display: flex;
      flex-direction: column; }
      .buscador-container .buscador__header img {
        height: auto;
        width: 100px;
        margin-bottom: 28px; }
      .buscador-container .buscador__header h2 {
        font-size: 23px;
        font-weight: 700; }
    .buscador-container .buscador__input {
      position: relative; }
      .buscador-container .buscador__input input {
        border: 1px solid black;
        border-radius: 15px;
        font-size: 24px;
        font-weight: 400;
        outline: none;
        padding: 5px 16px;
        width: 100%; }
        .buscador-container .buscador__input input::-webkit-input-placeholder {
          font-weight: 300; }
        .buscador-container .buscador__input input::placeholder {
          font-weight: 300; }
      .buscador-container .buscador__input img {
        right: 9px;
        top: 6px;
        width: 32px;
        position: absolute; }
    .buscador-container .buscador button {
      align-items: center;
      background: #c41230;
      border: none;
      border-radius: 3px;
      color: white;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      justify-content: center;
      letter-spacing: 0.3px;
      margin: 0 auto;
      width: 95%; }
      .buscador-container .buscador button:hover {
        background: #950e25; }
      .buscador-container .buscador button:disabled {
        background: #f9c2cb; }

.multi-progress-bar {
  width: 100%;
  min-height: 80px;
  padding: 10px 0; }
  .multi-progress-bar .mpb-title {
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    padding: 0 0 6px 0; }
  .multi-progress-bar .mpb-wrapper {
    background-color: #d9d9d9;
    color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    min-height: 30px;
    overflow: hidden;
    position: relative;
    width: 100%; }
    @media (max-width: 990px) {
      .multi-progress-bar .mpb-wrapper {
        min-height: 20px;
        max-height: 40px; } }
    .multi-progress-bar .mpb-wrapper > * {
      height: 100%;
      display: inline-block;
      text-align: center;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center; }
  .multi-progress-bar .mpb-labels {
    display: flex;
    font-weight: 400;
    flex-wrap: wrap; }
    .multi-progress-bar .mpb-labels > div {
      align-content: center;
      display: flex;
      font-size: 0.8rem;
      padding: 0 15px; }
      @media (min-width: 990px) {
        .multi-progress-bar .mpb-labels > div {
          font-size: .9rem; } }
      @media (min-width: 1156px) {
        .multi-progress-bar .mpb-labels > div {
          font-size: 1rem; } }
      .multi-progress-bar .mpb-labels > div span {
        font-size: 22px; }
        .multi-progress-bar .mpb-labels > div span::after {
          content: " ";
          padding: 3px; }

.vertical-progress-bar .vpb-bottom, .vertical-progress-bar .vpb-top {
  font-size: 0.6rem;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0; }
  @media (min-width: 770px) {
    .vertical-progress-bar .vpb-bottom, .vertical-progress-bar .vpb-top {
      font-size: 0.65rem; } }
  @media (min-width: 990px) {
    .vertical-progress-bar .vpb-bottom, .vertical-progress-bar .vpb-top {
      font-size: 0.8rem; } }
  @media (min-width: 1156px) {
    .vertical-progress-bar .vpb-bottom, .vertical-progress-bar .vpb-top {
      font-size: 1rem; } }

.vertical-progress-bar .vpb-wrapper {
  align-items: flex-end;
  background-color: #d9d9d9;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  height: 100px;
  overflow: hidden;
  width: 30px;
  font-size: 0.7rem;
  color: #ffffff; }
  @media (min-width: 770px) {
    .vertical-progress-bar .vpb-wrapper {
      width: 26px;
      font-size: 0.65rem; } }
  @media (min-width: 990px) {
    .vertical-progress-bar .vpb-wrapper {
      width: 35px;
      font-size: 0.8rem; } }
  @media (min-width: 1124px) {
    .vertical-progress-bar .vpb-wrapper {
      width: 40px; } }
  @media (min-width: 1256px) {
    .vertical-progress-bar .vpb-wrapper {
      width: 50px;
      font-size: 1rem; } }
  .vertical-progress-bar .vpb-wrapper .bar {
    align-content: center;
    align-self: center;
    background-color: #002e7b;
    border-radius: 12px 12px 0 0;
    display: flex;
    font-weight: 600;
    justify-content: center;
    width: 100%;
    z-index: 50; }
  .vertical-progress-bar .vpb-wrapper .percentage {
    position: relative;
    margin-top: auto;
    margin-bottom: auto; }
  .vertical-progress-bar .vpb-wrapper .shadow {
    background-color: #83aff7;
    border-radius: 12px 12px 0 0;
    margin-bottom: -6px;
    width: 100%;
    z-index: 49; }

@-webkit-keyframes fadeinout {
  0% {
    opacity: 1;
    position: relative; }
  100% {
    opacity: 0;
    display: none;
    position: absolute;
    top: 0; } }

@keyframes fadeinout {
  0% {
    opacity: 1;
    position: relative; }
  100% {
    opacity: 0;
    display: none;
    position: absolute;
    top: 0; } }

@-webkit-keyframes fadeoutin {
  0% {
    opacity: 0;
    display: none !important; }
  100% {
    opacity: 1; } }

@keyframes fadeoutin {
  0% {
    opacity: 0;
    display: none !important; }
  100% {
    opacity: 1; } }

.v-dashboard {
  height: 100%;
  max-height: 100%;
  margin: 0px; }
  .v-dashboard > div {
    height: 100%;
    max-height: 100%;
    overflow-y: hidden; }
  .v-dashboard__data {
    padding: 45px;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    height: 95%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1250px;
    overflow-y: hidden;
    overflow-x: hidden; }
    .v-dashboard__data .d-header > div, .v-dashboard__data .d-pdp-general > div:first-child {
      display: flex;
      align-items: flex-end; }
      .v-dashboard__data .d-header > div:nth-child(2), .v-dashboard__data .d-pdp-general > div:first-child:nth-child(2) {
        justify-content: flex-end; }
    .v-dashboard__data > div .header {
      border-bottom: #000 1px solid;
      padding-bottom: 0px !important; }
    .v-dashboard__data .d-empty-filters {
      align-items: center;
      display: flex;
      height: 100%;
      font-size: 2rem;
      justify-content: center;
      opacity: 1;
      width: 100%;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase;
      -webkit-animation: fadeoutin 1s linear forwards;
              animation: fadeoutin 1s linear forwards; }
      .v-dashboard__data .d-empty-filters > div {
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .v-dashboard__data .d-empty-filters > div:first-child {
          height: 90%; }
        .v-dashboard__data .d-empty-filters > div:last-child {
          height: 10%;
          font-size: 1.4rem; }
      .v-dashboard__data .d-empty-filters svg {
        width: 10rem;
        margin: 0px auto; }
    .v-dashboard__data .d-loading-request {
      opacity: 0;
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      font-size: 2rem;
      width: 100%;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase; }
      .v-dashboard__data .d-loading-request.notVisible {
        -webkit-animation: fadeinout 1.5s linear forwards;
                animation: fadeinout 1.5s linear forwards; }
      .v-dashboard__data .d-loading-request.isVisible {
        -webkit-animation: fadeoutin 1s linear forwards;
                animation: fadeoutin 1s linear forwards; }
      .v-dashboard__data .d-loading-request > div {
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center; }
      .v-dashboard__data .d-loading-request svg {
        width: 10rem;
        margin: 0px auto; }
        .v-dashboard__data .d-loading-request svg:first-child {
          height: auto;
          width: 6rem;
          margin-bottom: 3rem; }
    .v-dashboard__data .d-pdp-general .d-pdp-charts > div {
      padding-right: 20px; }
      .v-dashboard__data .d-pdp-general .d-pdp-charts > div:last-child {
        padding-left: 20px;
        border-left: 1px solid transparent;
        border-image: linear-gradient(to bottom, white, white, black, black, white, white) 1; }
    .v-dashboard__data .d-pdp-general .d-tags {
      display: flex;
      justify-content: flex-end;
      max-width: 60%; }
      .v-dashboard__data .d-pdp-general .d-tags div {
        padding: 0px; }
      .v-dashboard__data .d-pdp-general .d-tags div:not(:last-child)::after {
        content: "|";
        padding: 15px; }
    .v-dashboard__data .d-pdp-month .d-charts {
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      flex-wrap: wrap;
      justify-content: space-around; }
      .v-dashboard__data .d-pdp-month .d-charts:last-child {
        border-left: 1px solid transparent;
        border-image: linear-gradient(to bottom, white, white, black, black, white, white) 1;
        justify-content: space-evenly; }
    .v-dashboard__data .d-photo-schedule table th, .v-dashboard__data .d-photo-schedule table td {
      border: none; }
  .v-dashboard__filters {
    height: 100%;
    background-color: #efefef; }
    .v-dashboard__filters .title {
      text-align: center;
      font-size: 22px;
      margin-bottom: 10px;
      margin-top: 15px; }
    .v-dashboard__filters div.control, .v-dashboard__filters title {
      margin-top: 30px; }
    .v-dashboard__filters div.select, .v-dashboard__filters select {
      width: 100%; }
    .v-dashboard__filters .action-btn button {
      font-size: inherit; }
      .v-dashboard__filters .action-btn button.underline {
        background-color: transparent;
        border: none;
        text-decoration: underline; }
        .v-dashboard__filters .action-btn button.underline svg {
          margin-right: 5px; }
    @media (max-width: 770px) {
      .v-dashboard__filters .action-btn {
        font-size: 1rem; } }
    @media (min-width: 771px) {
      .v-dashboard__filters .action-btn {
        font-size: 1.2rem; } }

.container {
  padding-bottom: 0px;
  padding-top: 0px; }
  .container.is-fluid {
    margin: 0px !important; }

.is-top-marginless {
  margin-top: 0 !important; }

.react-datepicker-wrapper input {
  background-color: #fff;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(.375em - 1px) calc(.625em - 1px);
  position: relative;
  vertical-align: top; }

.react-datepicker-wrapper input:active {
  border-color: #3273dc;
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25); }

.hero.is-success {
  background: #F2F6FA; }

.hero .nav, .hero.is-success .nav {
  box-shadow: none; }

.box {
  margin-top: 5rem; }

.avatar {
  margin-top: -70px;
  padding-bottom: 20px; }

.avatar img {
  padding: 5px;
  background: #fff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1); }

.avatar-circle {
  border-radius: 50%; }

html {
  overflow-y: auto; }

.content-app {
  margin-bottom: 0px !important; }

.is-borderless {
  border: 0 !important; }

.is-bg-transparent {
  background: transparent !important; }

.react-contextmenu-wrapper .muestra-context {
  align-items: center;
  display: flex;
  flex-direction: column; }
  .react-contextmenu-wrapper .muestra-context .image {
    margin: 4px 0 0;
    left: 2px;
    position: relative; }
    .react-contextmenu-wrapper .muestra-context .image .is-rounded {
      border: 1px solid #d2d8d8;
      height: 50px;
      width: 50px; }

.react-contextmenu {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 1px 1px 8px #cfcfcf;
  color: black;
  cursor: pointer;
  display: none;
  margin-left: -75px;
  margin-top: -75px;
  position: relative;
  z-index: 999; }
  .react-contextmenu--visible {
    display: block; }
  .react-contextmenu-item {
    align-items: center;
    display: flex;
    font-size: 17px;
    min-height: 40px;
    outline: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
    .react-contextmenu-item button {
      align-items: center;
      background: none;
      border: none;
      display: flex;
      font-size: 16px;
      height: 40px;
      overflow: hidden;
      outline: none;
      padding: 2px 10px;
      position: relative;
      width: 100%; }
      .react-contextmenu-item button:after {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 100%;
        content: "";
        height: 0;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform-style: flat;
                transform-style: flat;
        -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
        transition: width 0.6s ease, height 0.6s ease;
        width: 0; }
      .react-contextmenu-item button:active:after {
        height: 500px;
        width: 500px; }
      .react-contextmenu-item button .zoom-in,
      .react-contextmenu-item button .zoom-out {
        background: url(/static/media/zoom-in.63dfba79.svg) no-repeat center;
        background-size: 18px;
        display: block;
        height: 40px;
        margin: 0;
        width: 40px; }
      .react-contextmenu-item button .zoom-out {
        background-image: url(/static/media/zoom-out.9fdb4f0a.svg); }
      .react-contextmenu-item button .zoom-in,
      .react-contextmenu-item button .zoom-out {
        display: block;
        background: url(/static/media/zoom-in.63dfba79.svg) no-repeat center;
        background-size: 18px;
        height: 40px;
        margin: 0;
        width: 40px; }
      .react-contextmenu-item button .zoom-out {
        background-image: url(/static/media/zoom-out.9fdb4f0a.svg); }
    .react-contextmenu-item:hover {
      background: #2f6ac9;
      color: white; }
      .react-contextmenu-item:hover button {
        color: white; }
      .react-contextmenu-item:hover .zoom-in {
        background-image: url(/static/media/zoom-in-fill.6d1bd61d.svg); }
      .react-contextmenu-item:hover .zoom-out {
        background-image: url(/static/media/zoom-out-fill.8c4d21c4.svg); }
    .react-contextmenu-item .muestra-context {
      padding: 0 10px; }
    .react-contextmenu-item--disabled {
      background: #e24a4a;
      padding-left: 40px;
      text-decoration: line-through;
      -webkit-text-decoration-color: white;
              text-decoration-color: white; }
      .react-contextmenu-item--disabled button {
        color: white; }
        .react-contextmenu-item--disabled button .zoom-in {
          display: none; }
        .react-contextmenu-item--disabled button .zoom-out {
          display: none; }
      .react-contextmenu-item--disabled:hover {
        background: #e24a4a;
        color: white; }

.Eliminar_muestra {
  margin: 0 !important;
  padding: 0 10px; }
  .Eliminar_muestra:hover {
    background: #e24a4a !important;
    color: white; }
  .Eliminar_muestra .react-contextmenu-item:hover {
    background: inherit; }

.total-zoom {
  align-items: center;
  border-bottom: 1px solid black;
  display: flex;
  font-weight: 600;
  height: 35px;
  justify-content: center;
  padding-left: 5px; }
  .total-zoom p {
    width: 73%; }
  .total-zoom span {
    align-items: center;
    border-left: 1px solid black;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    width: 27%; }

.react-confirm-alert-overlay {
  z-index: 100; }

.bg-png {
  background-image: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gKwSUNDX1BST0ZJTEUAAQEAAAKgbGNtcwQwAABtbnRyUkdCIFhZWiAH5QAEAA8AEQAHAAphY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA1kZXNjAAABIAAAAEBjcHJ0AAABYAAAADZ3dHB0AAABmAAAABRjaGFkAAABrAAAACxyWFlaAAAB2AAAABRiWFlaAAAB7AAAABRnWFlaAAACAAAAABRyVFJDAAACFAAAACBnVFJDAAACFAAAACBiVFJDAAACFAAAACBjaHJtAAACNAAAACRkbW5kAAACWAAAACRkbWRkAAACfAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACQAAAAcAEcASQBNAFAAIABiAHUAaQBsAHQALQBpAG4AIABzAFIARwBCbWx1YwAAAAAAAAABAAAADGVuVVMAAAAaAAAAHABQAHUAYgBsAGkAYwAgAEQAbwBtAGEAaQBuAABYWVogAAAAAAAA9tYAAQAAAADTLXNmMzIAAAAAAAEMQgAABd7///MlAAAHkwAA/ZD///uh///9ogAAA9wAAMBuWFlaIAAAAAAAAG+gAAA49QAAA5BYWVogAAAAAAAAJJ8AAA+EAAC2xFhZWiAAAAAAAABilwAAt4cAABjZcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltjaHJtAAAAAAADAAAAAKPXAABUfAAATM0AAJmaAAAmZwAAD1xtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAEcASQBNAFBtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEL/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAUABQDAREAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAUDCP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeqSMHEDTQjFkQf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAgMEFDMQ/9oACAEBAAEFAix1NDkcaypnTyvyP//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAADD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAECABAyEf/aAAgBAQAGPwKNWoGYdJmaWv/EABoQAAIDAQEAAAAAAAAAAAAAAAGxABDwEUH/2gAIAQEAAT8hiSrICegZHsyJpp1//9oADAMBAAIAAwAAABCQQACf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAMP/aAAgBAgEBPxAf/8QAHBAAAQQDAQAAAAAAAAAAAAAAAQAQIfARYdEx/9oACAEBAAE/EFU0arkpEGlDOCQIEeAKrs1TZv/Z");
  background-size: 20px 20px;
  background-position: 0 0;
  background-repeat: repeat; }
  .bg-png .magnifying-glass {
    background-color: #ff0000 !important; }

