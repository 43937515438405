$white: #ffffff;
$black: #222222;
.curbside {
  background: #f7f7f7;
  color: $black;
  font-family: "Roboto", Helvetica, sans-serif;
  min-height: 100vh;
  margin-top: -12px;
  margin-bottom: -20px;
  padding-bottom: 16px;
  @media (min-width: 768px) {
    background: $white;
  }
  &.info {
    left: 0;
    position: absolute;
    top: 0;
    margin: 0;
    width: 100vw;
  }
  &.error {
    padding: 0 9%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__link-copy {
    height: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
    z-index: -1;
  }
  &__header {
    align-items: center;
    background: $white;
    border-bottom: solid 1px #eee;
    display: flex;
    height: 60px;
    justify-content: space-between;
    margin-bottom: 9px;
    padding: 0 18px;
    position: relative;
    @media (min-width: 768px) {
      padding: 0 9%;
    }
    &-logo {
      &-dockers {
        font-size: 7vw;
        @media (min-width: 768px) {
          font-size: 38px;
        }
      }
      &-levis {
        max-width: 65px;
        @media (min-width: 768px) {
          max-width: 85px;
        }
      }
    }
    &-pc9 {
      cursor: default;
      font-size: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      position: absolute;
      pointer-events: none;
      width: 100%;
    }
    .curbside__share {
      box-sizing: border-box;
      color: rgb(0, 0, 0);
      cursor: pointer;
      height: 45px;
      padding: 10px;
      width: 45px;
      @media (min-width: 768px) {
        height: 48px;
        width: 48px;
      }
      &:hover {
        background: rgb(248, 248, 248);
      }
    }
  }
  &__flexContainer {
    padding: 0 9px;
    @media (min-width: 768px) {
      padding: 0 9%;
    }
    @media (min-width: 1200px) {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 60px;
    }
  }
  &__imagenes {
    background: $white;
    height: 359px;
    margin-bottom: 23px;
    position: relative;
    width: 100%;
    @media (min-width: 768px) {
      height: 550px;
    }
    @media (min-width: 1200px) {
      width: 55%;
    }
  }
  &__body,
  &__compra {
    margin-bottom: 9px;
  }
  &__body {
    @media (min-width: 1200px) {
      width: 45%;
    }
  }
  &__compra {
    margin-top: 16px;
    box-sizing: border-box;
    @media (min-width: 1200px) {
      width: 45%;
      order: 4;
    }
  }
  &__faqs {
    background: $white;
    border-radius: 1px;
    box-sizing: border-box;
    padding: 1px 12px 2px;
    margin-bottom: 50px;
    margin-top: 16px;
    @media (min-width: 1200px) {
      box-sizing: border-box;
      margin: -30px 0 0;
      width: 55%;
    }
    &-item {
      align-items: center;
      display: flex;
      margin: 12px 0;
      a,
      span {
        color: #222;
        font-size: 15px;
        font-weight: 400;
        margin-left: 8px;
      }
      svg {
        color: rgb(0, 0, 0);
      }
      @media (min-width: 1200px) {
        &:first-child {
          margin-right: 40px;
          padding-top: 30px;
          border-top: solid 1px #eee;
        }
      }
    }
  }
  &__footer {
    align-items: center;
    bottom: 0;
    background: $white;
    border-top: solid 1px #eee;
    display: flex;
    height: 49px;
    justify-content: space-between;
    left: 0;
    margin-top: 9px;
    padding: 0 18px;
    position: absolute;
    right: 0;
    a,
    span {
      color: #222222;
      font-size: 15px;
      font-weight: 400;
    }
    a {
      height: 20px;
      box-sizing: border-box;
      &:hover {
        border-bottom: 1px solid #222;
      }
    }
    @media (min-width: 768px) {
      padding: 0 9%;
    }
  }
  &__compartelo {
    align-items: center;
    background: #c41230;
    border: 1px solid #c41230;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    letter-spacing: 0.3px;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 20px;
    width: 95%;
    &:hover {
      background: darken(#c41230, 10);
    }
    svg {
      font-size: 10px;
      margin-right: 9px;
      position: relative;
    }
    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: 80%;
    }
    @media (min-width: 1200px) {
      margin: 0;
      margin-left: auto;
      margin-right: 9%;
      max-width: 35%;
    }
  }
}
