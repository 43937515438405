.carrusel {
  position: relative;
  height: 100%;
  user-select: none;
  width: 100%;
  display: flex;
  &__right,
  &__left {
    background: white;
    box-shadow: 0px 0px 2px #2222229f;
    color: #929292;
    border-radius: 100%;
    font-weight: lighter;
    height: 45px;
    padding: 13px 16px 13px 13px;
    position: absolute;
    z-index: 99;
    top: 40%;
    width: 45px !important;
  }
  &__right {
    right: 10px;
    @media (min-width: 768px) {
      right: 9%;
    }
    @media (min-width: 1200px) {
      right: 15%;
    }
  }
  &__left {
    left: 10px;
    transform: rotate(180deg);
    @media (min-width: 768px) {
      left: 9%;
    }
    @media (min-width: 1200px) {
      left: 15%;
    }
  }
  img {
    height: auto;
    width: 100%;
    pointer-events: none;
    object-fit: contain;
  }
  &__dots {
    align-items: center;
    bottom: 0px;
    display: flex;
    justify-content: center;
    margin-bottom: -15px;
    position: absolute;
    width: 100%;
    @media (min-width: 1200px) {
      display: none;
    }
    &-circle {
      border-radius: 100%;
      background: rgba(182, 182, 182, 0.74);
      display: block;
      height: 9px;
      margin-left: 5px;
      width: 9px;
      &:first-child {
        margin-left: 0;
      }
      &-active {
        background: black;
      }
    }
  }
}

.carrusel-mini {
  display: none;
  @media (min-width: 1200px) {
    height: 100%;
    left: -13%;
    position: absolute;
    overflow: hidden;
    scroll-behavior: smooth;
    top: 0;
    width: 90px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__img {
      align-items: center;
      border: 1px solid #eee;
      cursor: pointer;
      display: flex;
      height: 90px;
      justify-content: center;
      width: 90px;
      &-active {
        border-color: #222;
      }
      img {
        max-height: 80px;
        object-fit: contain;
      }
    }
  }
}
