@page {
  size: letter portrait;
  margin: 0mm;
}
@media print {
  .modal-container,
  .Toastify,
  .Toastify__toast-body,
  nav,
  .support,
  svg,
  .curbside button,
  .carrusel svg,
  .carrusel-mini {
    display: none;
  }
  .curbside__header-logo-levis,
  .curbside__header-logo-dockers {
    display: block;
  }
}

.imprimir {
  align-items: center;
  background: none;
  border: none;
  color: #c41230;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.3px;
  margin: 20px auto 0px;
  outline: none;
  padding-bottom: 2px;
  transition: 0.3s;
  &:hover {
    border-bottom: 1px solid #c41230;
  }
  svg {
    font-size: 20px;
    margin-right: 9px;
    position: relative;
  }
  @media (min-width: 1200px) {
    margin-right: 22%;
    max-width: 35%;
  }
}
