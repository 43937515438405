.generate-channel-link {
    min-height: 250px;
    max-height: 60vh;
}

.generate-channel-link > div.box {
    display: flex;
}

.generate-channel-link > div.box:last-child {
    margin-top: 0.5rem;
    justify-content: space-between;
    flex-direction: column;
}

.generate-channel-link > div.box:last-child > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.generate-channel-link > div.box:first-child {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0.5rem;
}

.generate-channel-link > div.box:first-child > div {
    display: flex;
    align-items: center;
    justify-content: center;
}
