
.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif !important;
}

.rbc-event{
  background-color:#359CEE;

}
.rbc-calendar .rbc-event-content, 
.rbc-calendar .rbc-toolbar-label,
.rbc-calendar .rbc-header,
.rbc-calendar .rbc-date-cell,
.rbc-calendar .rbc-btn-group,
.rbc-calendar .rbc-time-slot,
.rbc-calendar .rbc-label {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif !important;
}

// .rbc-calendar *,
// .rbc-calendar *:before,
// .rbc-calendar *:after {
//   box-sizing: inherit;
// }

// .rbc-abs-full {
//   overflow: hidden;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }

// .rbc-ellipsis {
//   display: block;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

// .rbc-rtl {
//   direction: rtl;
// }

// .rbc-off-range {
//   color: $out-of-range-color;
// }

// .rbc-off-range-bg {
//   background: $out-of-range-bg-color;
// }

// .rbc-header {
//   overflow: hidden;
//   flex: 1 0 0%;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   padding: 0 3px;
//   text-align: center;
//   vertical-align: middle;
//   font-weight: bold;
//   font-size: 90%;
//   min-height: 0;
//   border-bottom: 1px solid $cell-border;

//   & + & {
//     border-left: 1px solid $cell-border;
//   }

//   .rbc-rtl & + & {
//     border-left-width: 0;
//     border-right: 1px solid $cell-border;
//   }

//   & > a {
//     &, &:active, &:visited {
//       color: inherit;
//       text-decoration: none;
//     }
//   }
// }

// .rbc-row-content {
//   position: relative;
//   user-select: none;
//   -webkit-user-select: none;
//   z-index: 4;
// }

// .rbc-today {
//   background-color: $today-highlight-bg;
// }
