.modal-package .header {
    margin-top: 0px;
}


.package-share-width .shared, .package-share-width .filtered {
    //margin-top: -12px;
    //padding-top: 0px;
    min-height: 150px;
    max-height: 200px;
    overflow-y: auto;
}

.package-share-width .shared .tags {
    margin-bottom: 0px;
}
