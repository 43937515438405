.notFound {
  align-items: center;
  color: #222222;
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto", Helvetica, sans-serif;
  justify-content: center;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
  &__error {
    align-items: center;
    flex-direction: column;
    display: flex;
    @media (min-width: 768px) {
      order: 2;
    }
  }
  &__ops {
    font-size: 23px;
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    span {
      font-size: 30px;
      font-weight: bolder;
    }
    &.mobile {
      display: block;
      @media (min-width: 768px) {
        display: none;
      }
    }
    &.tablet {
      display: none;
      @media (min-width: 768px) {
        margin-bottom: 0;
        text-align: left;
        line-height: 40px;
        display: block;
      }
    }
  }
  &__info {
    @media (min-width: 768px) {
      max-width: 370px;
    }
  }
  &__buscar {
    font-size: 22px;
    margin: 20px 0;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
  h1 {
    -webkit-text-stroke: 1px #354a70;
    color: #69829c;
    font-size: 170px;
    font-weight: bolder;
    line-height: 100px;
    text-align: center;
    text-shadow: 1px 1px 1px #354a70, 2px 2px 1px #354a70;
    width: 100%;
    @media (min-width: 768px) {
      font-size: 200px;
      line-height: 150px;
    }
  }
  &__svg {
    position: relative;
    left: 17px;
    max-height: 400px;
    @media (min-width: 768px) {
      font-size: 25px;
      max-height: 600px;
    }
  }
  &__Ir {
    align-items: center;
    background: #c41230;
    border: 1px solid #c41230;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    letter-spacing: 0.3px;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 100%;
    &:hover {
      background: darken(#c41230, 10);
      color: #ffffff;
    }
  }
}
